<template>
  <div class="small-text">
    <b-modal size="sm" v-if="selected" v-model="showEditModal">
      <fieldset>
        <legend>
          <b-badge class="legend-title"
            >Editar Encuesta:
            <b-badge v-if="selected" style="background: white; color: black">{{
              selected.name
            }}</b-badge></b-badge
          >
        </legend>

        <div v-if="selected" class="d-flex flex-column">
          <text-input v-model="selected.name" label="Nombre" />

          <select-input
            v-model="selected.brandId"
            label="Marca"
            :opts="brands"
            prop_label="name"
            prop_value="id"
          ></select-input>

          <text-input v-model="selected.description" label="Descripción" />

          <checkbox-input v-model="selected.active" label="Activo" />

          <number-input
            label="Limitar num apariciones"
            v-model="selected.limitRecords"
          ></number-input>

          <div class="d-flex mb-2 justify-content-between">
            <div class="d-flex flex-column mr-3">
              <label class="small-label">Se repite: </label>

              <input class="mr-2" type="checkbox" v-model="selected.repeats" />
            </div>
            <template>
              <div class="d-flex flex-column">
                <label class="small-label">Cada: </label>
                <div class="d-flex">
                  <input
                    :disabled="!selected.repeats"
                    style="max-width: 100px"
                    min="0"
                    class="input-small mr-1"
                    type="number"
                    v-model="selected.repeatsUnit"
                  />
                  <select
                    :disabled="!selected.repeats"
                    class="cd-select"
                    style="height: 20px; width: 80px"
                    v-model="selected.repeatsValue"
                  >
                    <option value="days">dias</option>
                    <option value="weeks">semanas</option>
                    <option value="months">meses</option>
                  </select>
                </div>
              </div>
            </template>
          </div>
        </div>
      </fieldset>

      <template #modal-footer>
        <b-button
          size="sm"
          class="small-button"
          variant="outline-success"
          @click="onSaveClick"
        >
          <b-icon icon="cloud-upload"></b-icon> guardar
        </b-button>
        <b-button
          size="sm"
          class="small-button"
          variant="outline-secondary"
          @click="showEditModal = false"
        >
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <div style="max-width: 1200px" class="p-2">
      <b-button
        class="small-button mb-1"
        size="sm"
        variant="outline-primary"
        @click="onItemEdit(null)"
        ><b-icon icon="plus-circle"></b-icon> nueva</b-button
      >

      <b-table
        show-empty
        small
        :current-page="currentPage"
        :per-page="perPage"
        striped
        bordered
        stacked="sm"
        :items="surveys"
        :fields="fields"
      >
        <template v-slot:cell(name)="row">
          {{ row.item.name }}
        </template>

        <template v-slot:cell(opts)="row">
          <div class="d-flex justify-content-between">
            <div>
              <b-button
                class="mr-1 btn-small"
                variant="outline-primary"
                @click="onItemEdit(row.item)"
                size="sm"
                ><b-icon icon="box-arrow-in-down-left"></b-icon
              ></b-button>

              <b-button
                class="mr-1 btn-small"
                variant="outline-danger"
                size="sm"
                @click="onItemRemove(row.item)"
                ><b-icon icon="trash"></b-icon
              ></b-button>
            </div>

            <router-link :to="`surveys/editor/${row.item.id}`">ver</router-link>
          </div>
        </template>

        <template v-slot:cell(brand)="row">
          {{ row.item.brand ? row.item.brand.name : "-" }}
        </template>
      </b-table>

      <b-pagination
        style="max-width: 150px;   margin: auto;"
        v-model="currentPage"
        :total-rows="surveys.length"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import _ from "lodash";
import { logError, logInfo } from "../../../../utils";
import { downloadCSVFile } from "../../../../csv";

export default {
  name: "SurveyList",
  components: {},
  data: function() {
    return {
      edited: null,
      showEditModal: false,
      surveys: [],
      currentPage: 1,
      perPage: 20,
      fields: [
        { key: "opts", label: "" },
        { key: "name", label: "Nombre", sortable: true },
        { key: "brand", label: "Marca", sortable: true },
        { key: "description", label: "Descripción" },
      ],
      selected: null,
      brands: [],
      test: [],
      keyCsvReader: 1, //used to rerender csv reader with key changing method
    };
  },

  computed: {},

  methods: {
    async downloadPos(item) {
      const filename = `pos_${item.name}.csv`;

      return downloadCSVFile(
        filename,
        item.pos.map((el) => ({ id: el.id })),
        (err) => {
          if (err) logError(err);
        }
      );
    },

    async onSaveClick() {
      if (this.selected.id) {
        await ApiService.put(`surveys/${this.selected.id}`, this.selected);
      } else {
        await ApiService.post("surveys", this.selected);
      }
      this.showEditModal = false;
      logInfo("Encuesta guardada");
      await this.fetch();
    },

    clearCsvReader() {
      this.keyCsvReader += 1;
    },

    onCsvData(data) {
      console.log(data);
      this.selected.pos = data;
    },

    onItemEdit(item) {
      this.clearCsvReader();
      this.selectItem(item);
    },

    selectItem(item) {
      this.showEditModal = true;
      this.selected = item
        ? _.cloneDeep(item)
        : {
            name: null,
            brandId: null,
            active: true,
            limitRecords: null,
            repeats: null,
            repeatsUnit: null,
            repeatsValue: null,
            pos: [],
          };
    },

    async onItemRemove(obj) {
      if (window.confirm("Vas a borrar una encuesta, estás seguro?")) {
        await ApiService.delete(`surveys/${obj.id}`);
        logInfo("Encuesta borrada");
        await this.fetch();
      }
    },

    async fetch() {
      let resp = await ApiService.get("surveys");
      this.surveys = resp.data;
      resp = await ApiService.get("brands");
      if (resp.data) this.brands = [{ id: null, name: null }].concat(resp.data);
    },

    init() {
      this.fetch();
    },
  },

  async mounted() {
    this.init();
  },
};
</script>

<style>
input[type="file"] {
  height: 20px;
  font-size: 11px;
}
input[type="file"]::-webkit-file-upload-button {
  height: 20px;
  font-size: 11px;
}

.table-sm td,
.table-sm th {
  padding-top: 0;
  padding-bottom: 0;
}

.my-picture-input {
  border: 1px solid #ccc;
  margin: 0px !important;
}
</style>
